<template>
  <v-container fill-height fluid ma-0 pa-0 :style="backgroundStyle">
    <v-row class="d-flex justify-center">
      <v-col cols="12" md="8" class="pa-0">
        <v-card
          class="mx-xs-0 pb-4 mx-auto px-2"
          :width="cardWidth"
        >
          <card-toolbar
            :disableSave="!valid"
            button-mode
            class="mx-2 my-0 pt-4"
            title="Forgot Password"
          />
          <v-card-text class="pt-8">
            <v-form
              v-model="valid"
              @submit.prevent
            >
              <v-row align="center" justify="center">
                <v-col cols="12">
                  <v-text-field
                    v-model="email"
                    counter="255"
                    label="InvisMi iCare Email"
                    :prepend-icon="EMAIL_ICON"
                    :rules="rules"
                    class="mx-1"
                    color="primary"
                    dense
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="mx-2">
            <v-btn
              block
              @click="sendPasswordReset()"
              color="primary"
              :disabled="!valid"
              :loading="loading"
            >
              Reset Password
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, } from 'vuex'
import { transformError } from '../services/utility.service'

import { ICONS, ROUTES } from '../constants'
import { push } from '../router'

export default {
  components: {
    CardToolbar: () => import('../components/layout/toolbars/CardToolbar.vue')
  },
  data: () => ({
    initialized: false,
    loading: false,

    valid: false,
    email: null,

    rules: [
      v => !!v || 'Value is required.',
      v => v && v.toString().length <= 255 || 'Must have 128 characters or fewer.'
    ],
  }),
  computed: {
    ...mapGetters({
      backgroundStyle: 'sitemap/getBackgroundStyle',
    }),
    breakpoint () {
      return this.$vuetify.breakpoint.name
    },
    cardWidth () {
      return this.isMobileSize ? '100%' : '400px'
    },
    errorMessages () {
      if (this.fields.length > 0 && this.fields[0].value && this.fields[1].value) {
        if (this.fields[0].value.toString() !== this.fields[1].value.toString()) {
          return ['Passwords must match.']
        }
      }
      return []
    },
    isMobileSize () {
      return this.breakpoint === 'xs'
    },
  },
  methods: {
    ...mapActions ({
      resetPassword: 'user/resetPassword',
      errorMessage: 'message/errorMessage',
      resetUser: 'user/resetUser',
      successMessage: 'message/successMessage',
      setBackground: 'sitemap/setBackground',
    }),
    initTemplateConstants () {
      this.EMAIL_ICON = ICONS.EMAIL
    },
    sendPasswordReset () {
      this.loading = true
      this.resetPassword({ email: this.email, })
        .then(async response => {
          this.successMessage('Password reset email sent, check the email associated with this account.')
          await new Promise(resolve => setTimeout(resolve, 1000));
          push(ROUTES.SIGN_IN)
        })
        .catch(error => {
          this.errorMessage(`Failed to send password reset email. ${transformError(error)}`)
        })
        .finally(() => {
          this.loading = false
        })
    },
    showPasswordToggle (index) {
      this.fields[index].showPassword = !this.fields[index].showPassword
      this.fields[index].type = this.fields[index].showPassword ? 'text' : 'password'
      this.fields[index].appendIcon = this.fields[index].showPassword ? ICONS.HIDE : ICONS.SHOW
    },
  },
  async created() {
    this.initTemplateConstants()

    this.setBackground()

    this.initialized = true
  }
}
</script>
